import React from 'react';
import { Link } from 'react-router-dom';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import { routes } from '../../data/constants';

const PageHeader = ({ onSave, page, saving, errors, context }) => {
  return (
    <div className='builder-flex builder-w-full builder-justify-between builder-items-center builder-sticky builder-top-0 builder-z-40'>
      <div className={`builder-flex builder-flex-row builder-items-center ${saving ? 'builder-bg-gray-100 builder-animate-pulse' : ''}`}>
        <Link to={routes.PAGES} data-testid='pages-back-to-overview-button' className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100 builder-mr-4'>
          <i className='fa fa-chevron-left builder-mr-2' />
          Overview
        </Link>
        <div className='builder-flex builder-flex-col builder-mr-4'>
          <div className='builder-text-2xl builder-text-black builder-font-bold'>Page</div>
          <div>
            Path: <span className='builder-font-semibold'>{page?.url}</span>
            <span className='builder-mx-2'>|</span>
            Type: <span className='builder-font-semibold'>{page?.type}</span>
          </div>
        </div>
      </div>
      <div className='builder-flex builder-space-x-4 builder-items-center'>
        <ButtonWithIndicator
          dataTestId='page-details-add-button'
          loading={saving}
          disabled={saving || Object.keys(errors).length !== 0}
          onClick={onSave}
          icon='fal fa-save'
          text='Save'
          colorClass='builder-bg-primary builder-text-white'
          borderClass='builder-border builder-border-bg-blue-500'
          className='builder-ml-0 builder-mr-0'
        />
      </div>
    </div>
  );
};

export default PageHeader;

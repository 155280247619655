import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { routes } from '../../features/data/constants';
import { DispatchContext, StateContext } from '../../App';
import { LoadingIndicator } from './index';
import { useQuery } from 'graphql-hooks';
import { setTrackingUsers, TRACKING_USERS_QUERY } from '../../features/data/tracking';

const PrivateRoute = ({ children, context, ...rest }) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const { loading, error, data } = useQuery(TRACKING_USERS_QUERY);

  useEffect(() => {
    if (data?.trackingUsers) {
      const users = data.trackingUsers.reduce((acc, item) => {
        acc[item.trackingId] = item;
        return acc;
      }, {});

      setTrackingUsers(dispatch, users);
    }
  }, [data]);

  if (state.session === undefined) {
    return <LoadingIndicator />;
  }

  const renderRoute = ({ location }) => {
    if (state.session.authenticated) {
      return children;
    } else {
      return (
        <Redirect
          to={{
            pathname: routes.LOGIN,
            state: { from: location }
          }}
        />
      );
    }
  };

  return <Route {...rest} render={renderRoute} />;
};

export default PrivateRoute;

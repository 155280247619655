import { useContext } from 'react';
import { useMutation } from 'graphql-hooks';
import { DispatchContext } from '../../App';
import { setSession, stateReset } from '../data/actions';
import { resetSessionToDefault } from '../data/session';
import { routes } from '../data/constants';
import { useHistory } from 'react-router-dom';
import { TRACKING_USER_ACTION_TYPES, TRACKING_USER_MUTATION } from '../data/tracking';

const SIGNOUT = `
    mutation Logout($application: Int!) {
        logout(application: $application)  {
           ok          
        }
    }`;

export function useSignOut(_dispatch = undefined) {
  const [signoutMutation] = useMutation(SIGNOUT);
  const [trackingUserMutation] = useMutation(TRACKING_USER_MUTATION);

  const dispatch = useContext(DispatchContext);

  let history = useHistory();

  const handleSignOut = (redirect = true) => {
    trackingUserMutation({ variables: { data: { route: null, action: TRACKING_USER_ACTION_TYPES.LEAVE } } });

    return signoutMutation({
      variables: {
        application: 0
      }
    }).then((result) => {
      setSession(_dispatch || dispatch, resetSessionToDefault());
      stateReset(_dispatch || dispatch);
      if (redirect) history.push(routes.LOGIN);
    });
  };

  return {
    signOut: (redirect = true) => {
      return handleSignOut(redirect);
    }
  };
}

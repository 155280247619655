import React, { useEffect, useReducer, useRef, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/common/PrivateRoute';
import { generalSettingsRoutes, routes } from './features/data/constants';
import { initialState, MODAL_TYPES, rootReducer } from './features/data/reducers';

import { initSession, updateSession } from './features/data/session';
import {
  customBlockCreationHandler,
  customBlockEditHandler,
  dataSetHandler,
  modalCloseHandler,
  pageCreationHandler,
  pageSelectHandler,
  pageUpdatSilentHandler,
  publishLogAddHandler,
  sessionTakeOverMessageSetHandler,
  setSession
} from './features/data/actions';
import Login from './features/authentication/Login';
import Page404 from './components/Page404';
import Dealership from './features/settings/dealership/Dealership';
import Dealerships from './features/settings/dealership/Dealerships';
import { initializePusher } from './pusher';
import { useManualQuery, useQuery, useMutation } from 'graphql-hooks';
import { DataQuery } from './features/data/graphql';
import PageCreateModal from './features/pages/PageCreateModal';
import { LoadingIndicator } from './components/common';
import DraftCreateModal from './features/drafts/DraftCreateModal';
import { filter, find, head, map, values } from 'lodash';
import PublishModal from './features/builder/components/PublishModal';
import PublishDraftToProductionModal from './features/drafts/PublishDraftToProductionModal';
import Builder from './features/builder/Builder';
import SessionRestrictionModal, { SessionRestrictionModalViews } from './features/session/SessionRestrictionModal';
import { useClientMessages } from './features/session/useClientMessages';
import { useSignOut } from './features/authentication/useSignout';
import Template from './components/Template';
import { default as CampaignsOverview } from './features/campaigns/overview/components/Overview';
import { default as CampaignDetail } from './features/campaigns/detail/components/Detail';
import { default as PagesOverview } from './features/pages/Overview';
import { default as BlogOverview } from './features/blog/Overview';
import { default as AdvertisementsOverview } from './features/advertisements/overview/components/Overview';
import { default as AdvertisementDetail } from './features/advertisements/detail/components/Detail';
import { default as BlocksOverview } from './features/blocks/Overview';
import { default as BlocksDetail } from './features/blocks/Detail';
import { default as VehicleFiltersOverview } from './features/filters/overview/components/Overview';
import { default as VehicleFiltersDetail } from './features/filters/detail/components/Detail';
import { default as FaqOverview } from './features/faq/overview/components/Overview';
import { default as TopicsOverview } from './features/faq/topics/overview/components/Overview';
import { default as FaqCategoryDetail } from './features/faq/categories/detail/components/Detail';
import { default as FaqQuestionGroupDetail } from './features/faq/question-groups/detail/components/Detail';
import { default as FaqQuestionDetail } from './features/faq/questions/detail/components/Detail';
import { default as FaqTopicDetail } from './features/faq/topics/detail/components/Detail';
import { default as CustomBlocksOverview } from './features/custom-blocks/overview/components/Overview';
import { default as CustomBlockDetails } from './features/custom-blocks/details/components/Details';
import { default as SeoOverview } from './features/seo/overview/components/Overview';
import { default as PageDetailsContainer } from './features/seo/details/components/PageDetailsContainer';
import { client, subscriptionClient } from './graphqlClient';
import { DefaultPageTypeNames } from './constants';
import GeneralSettings from './features/settings/general/GeneralSettings';
import Overview from './features/overview/Overview';
import { TRACKING_USER_MUTATION, TRACKING_USER_SUBSCRIPTION, updateTrackingUser, TRACKING_USER_ACTION_TYPES, updateTrackingPage } from './features/data/tracking';
import cogoToast from 'cogo-toast';
import { PagesQuery } from './features/pages/usePages';

export const StateContext = React.createContext();
export const DispatchContext = React.createContext();

const WS_STATUSSES = { connected: 'connected', disconnected: 'disconnected', connecting: 'connecting' };

function App() {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const [currentLanguage, setCurrentLanguage] = useState(undefined);
  const [fetchData, { loading: initialLoading, error: loadingError }] = useManualQuery(DataQuery);
  const currentState = state.history[state.history.length - 1];
  const { clientMessageTypes, userChannel } = useClientMessages();
  const [lastReload, setLastReload] = useState(undefined);
  const { signOut } = useSignOut(dispatch);
  const [trackingUserMutation] = useMutation(TRACKING_USER_MUTATION);

  const [isReleasing, setIsReleasing] = useState(false);

  const [wsStatus, setWsStatus] = useState();
  const [qlSubscribed, setQlSubscribed] = useState(false);

  const authRef = useRef(state?.session?.authenticated);
  const websiteIdRef = useRef(state.currentWebsite);
  useEffect(() => {
    websiteIdRef.current = state.currentWebsite;
  }, [state.currentWebsite]);

  const handlePageGetSilent = (id, websiteId, onSuccess, onError) => {
    const variables = {
      data: { id, websiteId }
    };

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PagesQuery, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        const page = result?.data?.pages?.[0];
        if (!page) {
          cogoToast.error('Get page failed');
          return;
        }

        pageUpdatSilentHandler(dispatch, page.id, page);
      })
      .catch((err) => {
        cogoToast.error(err);
        onError && typeof onError === 'function' && onError(err);
      });
  };

  const parseSubscriptionMessage = ({ data, errors }) => {
    // For now to test
    if (errors && errors.length > 0) {
      return;
    }
    if (data.trackingUser.action === TRACKING_USER_ACTION_TYPES.MOVE || data.trackingUser.action === TRACKING_USER_ACTION_TYPES.LEAVE) {
      updateTrackingUser(dispatch, data.trackingUser.trackingId, data.trackingUser);
    } else if (data.trackingUser.action === TRACKING_USER_ACTION_TYPES.PUBLISH) {
      handleOtherUserIsReleasing();
    } else if (data.trackingUser.action === TRACKING_USER_ACTION_TYPES.PUBLISH_DONE) {
      handleOtherUserReleaseDone();
    } else if (data.trackingUser.action === TRACKING_USER_ACTION_TYPES.UPDATE || data.trackingUser.action === TRACKING_USER_ACTION_TYPES.UPDATE_DONE) {
      updateTrackingPage(dispatch, data.trackingUser);
      if (data.trackingUser.action === TRACKING_USER_ACTION_TYPES.UPDATE_DONE) {
        // Get Page
        const routeParts = data.trackingUser.route.split('/');
        const pageId = routeParts[routeParts.length - 1];
        handlePageGetSilent(pageId, websiteIdRef.current);
      }
    }
  };

  const initSubscription = () => {
    if (!authRef.current) {
      // eslint-disable-next-line no-console
      console.log('USER NOT AUTHENTICATED');
      setQlSubscribed(false);
      return;
    }

    // eslint-disable-next-line no-console
    console.log('INIT SUBSCRIPTION');
    // eslint-disable-next-line no-console
    console.log(window.location.pathname);

    const request = {
      query: TRACKING_USER_SUBSCRIPTION,
      variables: { route: window.location.pathname }
    };

    setQlSubscribed(true);
    subscriptionClient.unsubscribeAll();
    const observable = subscriptionClient.request(request);
    const subscription = observable.subscribe({
      next: (result) => {
        parseSubscriptionMessage(result);
      },
      error: (errors) => {
        parseSubscriptionMessage({ errors });
      },
      complete: () => {
        // eslint-disable-next-line no-console
        console.log('UNSUBSCRIBED...');
        subscription.unsubscribe();
        setQlSubscribed(false);
      }
    });
  };

  useEffect(() => {
    authRef.current = state?.session?.authenticated;
    if (state?.session?.authenticated && !qlSubscribed) {
      initSubscription();
    }
  }, [state?.session?.authenticated]);

  const handleConnectedSubscription = () => {
    // eslint-disable-next-line no-console
    setWsStatus(WS_STATUSSES.connected);
    // initSubscription();
  };
  const handleReconnectedSubscription = () => {
    // eslint-disable-next-line no-console
    setWsStatus(WS_STATUSSES.connected);
    initSubscription();
  };

  const handleConnectingSubscription = () => {
    setWsStatus(WS_STATUSSES.connecting);
  };

  useEffect(() => {
    subscriptionClient.on('connected', handleConnectedSubscription);
    subscriptionClient.on('reconnected', handleReconnectedSubscription);
    subscriptionClient.on('reconnecting', handleConnectingSubscription);
    subscriptionClient.on('disconnected', () => {
      setWsStatus(WS_STATUSSES.disconnected);
    });
  }, []);

  useEffect(() => {
    // Only show popups when user is logged-in
    if (!state?.session?.authenticated) return;
    if (wsStatus === WS_STATUSSES.disconnected) {
      cogoToast.error('Disconnected');
    } else if (wsStatus === WS_STATUSSES.connected) {
      cogoToast.success('Connected');
    } else if (wsStatus === WS_STATUSSES.connecting) {
      // cogoToast.info('Connecting');
    }
  }, [wsStatus]);

  useEffect(() => {
    if (currentLanguage === undefined) {
      const currentState = state.history[state.history.length - 1];
      if (currentState && currentState.languages.length > 0) {
        const language = head(filter(currentState.languages, (l) => l.available));
        setCurrentLanguage(language.code);
      }
    }
  }, [state]);

  useEffect(() => {
    if (userChannel) {
      userChannel.bind('publish_output', function (data) {
        publishLogAddHandler(dispatch, data.message);
        if (data.message === 'command_completed') {
          setIsReleasing(false);
          trackingUserMutation({ variables: { data: { route: window.location.pathname, action: TRACKING_USER_ACTION_TYPES.PUBLISH_DONE } } });
        }
      });
    }
  }, [userChannel]);

  const domainName = () => {
    if (state && state.configuration) {
      let domainName = head(state.configuration.website.domains);
      if (domainName) {
        return domainName;
        //     } else {
        //         if (window) {
        //             try {
        //                 return `${window.location.origin.replace('builder.', '')}`
        //             } catch (e) {
        //                 return ''
        //             }
        //         }
      }
    }

    return '';
  };

  const website = find(state.websites, (w) => w.id === state.currentWebsite);

  const context = {
    culture: currentLanguage,
    builder: true,
    multiLanguage: false,
    mapBoxToken: 'pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w',
    pages: currentState.pages,
    // urls: DefaultPageNames,
    customBlocks: currentState.customBlocks,
    campaigns: currentState.campaigns,
    domainName: domainName(),
    // defaultPageNames: { ...DefaultPageNames },
    defaultPageNames: { ...DefaultPageTypeNames },
    environment: website && !website.isProduction ? 1 : 0
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [state.session]);

  const alertUser = (e) => {
    if (state?.session?.authenticated) {
      signOut(false);
    }
    e.returnValue = '';
  };

  const handleReloadPage = () => {
    setLastReload(new Date());
    handleFetchWebsiteData();
  };

  useEffect(() => {
    if (!state.contentTypeDefinitions && state.session && state.session.authenticated) {
      handleFetchWebsiteData();
    }
  }, [state.contentTypeDefinitions, state.session]);

  useEffect(() => {
    // Set home page selected initial
    if (!initialLoading && state.session && state.session.authenticated) {
      if (values(currentState.pages).length > 1) {
        const homeId = head(filter(Object.keys(currentState.pages), (key) => currentState.pages[key].type === 'home'));
        if (homeId) {
          pageSelectHandler(dispatch, homeId);
        }
      }
    }
  }, [initialLoading, state.websites, state.session]);

  const handleFetchWebsiteData = (websiteId = undefined) => {
    const options = {};
    if (websiteId) {
      options['variables'] = { websiteId: websiteId };
    }

    const website = find(state.websites, (w) => w.id === websiteId);
    client.setHeader('Environment', !website || (website && website.isProduction) ? '0' : '1');
    fetchData(options).then((result) => {
      dataSetHandler(dispatch, result.data);
    });
  };

  useEffect(() => {
    async function initializeSession() {
      const session = await initSession();
      setSession(dispatch, session);
    }

    initializeSession();
  }, [lastReload]);

  useEffect(() => {
    if (state.configuration && state.session && state.session.user) {
      initializePusher(state.configuration.company_code, state.session.sessionId);
    }
  }, [state.configuration, state.session]);

  useEffect(() => {
    if (userChannel) {
      userChannel.bind('take_over_request', function (data) {
        switch (data.type) {
          case clientMessageTypes.take_over_request:
            sessionTakeOverMessageSetHandler(dispatch, data, SessionRestrictionModalViews.TakeOverRequest);
            break;
          case clientMessageTypes.take_over_request_accepted:
            sessionTakeOverMessageSetHandler(dispatch, data, SessionRestrictionModalViews.TakeOverRequestAccepted);
            break;
          case clientMessageTypes.take_over_request_declined:
            sessionTakeOverMessageSetHandler(dispatch, data, SessionRestrictionModalViews.TakeOverRequestDeclined);
            break;
          case clientMessageTypes.force_sign_out:
            sessionTakeOverMessageSetHandler(dispatch, data, SessionRestrictionModalViews.ForceSignOut);
            break;
          default:
            break;
        }
      });
    }
  }, [userChannel]);

  const handleAuthenticated = (user, sessionId, otherSessionsCount) => {
    const updatedSession = updateSession(state.session, user, sessionId, otherSessionsCount);
    setSession(dispatch, updatedSession);
  };

  const handleCloseModal = (type) => {
    modalCloseHandler(dispatch, type);
  };

  const handleAddPage = (id, type, path, copyFrom, category, indexable, matchPath) => {
    const existingPage = find(values(currentState.pages), (page) => page.url === path && page.matchPath === matchPath);

    if (!existingPage) {
      pageCreationHandler(dispatch, id, type, path, copyFrom, category, indexable);
      pageSelectHandler(dispatch, id);
    } else {
      pageSelectHandler(dispatch, existingPage.id);
    }
  };

  const handleAddCustomBlock = (addedCustomBlock) => {
    const isExistingCustomBlock = find(currentState?.customBlocks, (customBlock) => customBlock?.name === addedCustomBlock?.id);
    if (isExistingCustomBlock) {
      customBlockEditHandler(dispatch, addedCustomBlock);
    } else {
      customBlockCreationHandler(dispatch, addedCustomBlock);
    }
  };

  const handleReleasing = () => {
    setIsReleasing(true);
    trackingUserMutation({ variables: { data: { route: window.location.pathname, action: TRACKING_USER_ACTION_TYPES.PUBLISH } } });
  };

  const handleOtherUserIsReleasing = () => {
    setIsReleasing(true);
    cogoToast.warn('Publish started');
  };

  const handleOtherUserReleaseDone = () => {
    setIsReleasing(false);
    cogoToast.info('Publish is done');
  };

  if (loadingError) {
    return (
      <div className='builder-rounded-md builder-bg-red-50 builder-p-4 builder-m-10'>
        <div className='builder-text-md builder-font-medium builder-text-red-800'>Error loading website. Please contact administrator.</div>
      </div>
    );
  }

  if (initialLoading) {
    return <LoadingIndicator />;
  }

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <Router>
          <React.Fragment>
            {/*ROUTES*/}
            <Switch>
              <Route path={routes.LOGIN}>
                <Login onAuthenticated={handleAuthenticated} />
              </Route>
              <PrivateRoute exact path={routes.HOME}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <Overview context={context} currentLanguage={currentLanguage} setCurrentLanguage={(langCode) => setCurrentLanguage(langCode)} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} />
                </Template>
              </PrivateRoute>
              <PrivateRoute exact path={`${routes.BUILDER}/:id?`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <Builder context={context} currentLanguage={currentLanguage} setCurrentLanguage={(langCode) => setCurrentLanguage(langCode)} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.PAGES}`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <PagesOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={(langCode) => setCurrentLanguage(langCode)} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.BLOG}`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <BlogOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={(langCode) => setCurrentLanguage(langCode)} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.CAMPAIGNS}`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <CampaignsOverview context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.CAMPAIGN_DETAIL}/:id?`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <CampaignDetail context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.ADVERTISEMENTS}`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <AdvertisementsOverview context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.ADVERTISEMENT_DETAIL}/:id?`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <AdvertisementDetail context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.FAQ_OVERVIEW}`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.FAQ_TOPICS}`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <TopicsOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.FAQ_TOPIC_DETAIL}/:id?`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqTopicDetail context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.FAQ_CATEGORY_DETAIL}/:id?`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqCategoryDetail context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.FAQ_QUESTION_GROUP_DETAIL}/:id?`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqQuestionGroupDetail context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.FAQ_QUESTION_DETAIL}/:id?`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <FaqQuestionDetail context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.CUSTOM_BLOCKS}`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <CustomBlocksOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.CUSTOM_BLOCK}/:id?`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <CustomBlockDetails context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} onBlockAdded={(addedBlock) => handleAddCustomBlock(addedBlock)} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.SEO_OVERVIEW}`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <SeoOverview context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.SEO_DETAILS}/:id`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <PageDetailsContainer context={context} currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
                </Template>
              </PrivateRoute>

              <PrivateRoute path={`${routes.BLOCKS}`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <BlocksOverview context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.BLOCKS_DETAIL}/:id?`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <BlocksDetail context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.VEHICLE_FILTERS}`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <VehicleFiltersOverview context={context} />
                </Template>
              </PrivateRoute>
              <PrivateRoute path={`${routes.VEHICLE_FILTERS_DETAIL}/:id?`}>
                <Template isReleasing={isReleasing} onChangeEnvironment={(websiteId) => handleFetchWebsiteData(websiteId)} context={context}>
                  <VehicleFiltersDetail context={context} />
                </Template>
              </PrivateRoute>

              {map(generalSettingsRoutes, (path) => (
                <PrivateRoute key={path} exact path={path}>
                  <GeneralSettings context={context} />
                </PrivateRoute>
              ))}

              <PrivateRoute path={`${routes.SETTINGS_DEALERSHIPS}`}>
                <Dealerships context={context} />
              </PrivateRoute>
              <PrivateRoute path={`${routes.SETTINGS_DEALERSHIP}/:id`}>
                <Dealership context={context} />
              </PrivateRoute>

              <Route path='*'>
                <Page404 />
              </Route>
            </Switch>

            {/*MODALS*/}
            <DraftCreateModal
              isOpen={state.modal && state.modal.type === MODAL_TYPES.DRAFT_CREATE}
              onCreate={(type, name, copyFrom) => {
                handleCloseModal(MODAL_TYPES.DRAFT_CREATE);
              }}
              onClose={() => handleCloseModal(MODAL_TYPES.DRAFT_CREATE)}
              context={context}
            />

            <PageCreateModal
              isOpen={state.modal && state.modal.type === MODAL_TYPES.PAGE_CREATE}
              type={state.modal && state.modal.payload.type ? state.modal.payload.type : undefined}
              onCreate={(id, type, path, copyFrom, category, indexable, matchPath) => {
                handleAddPage(id, type, path, copyFrom, category, indexable, matchPath);
                handleCloseModal(MODAL_TYPES.PAGE_CREATE);
              }}
              onClose={() => handleCloseModal(MODAL_TYPES.PAGE_CREATE)}
              context={context}
            />

            <PublishModal isOpen={state.modal && state.modal.type === MODAL_TYPES.PUBLISH_WEBSITE} isReleasing={isReleasing} onReleasing={handleReleasing} context={context} />

            <PublishDraftToProductionModal isOpen={state.modal && state.modal.type === MODAL_TYPES.PUBLISH_DRAFT_TO_PRODUCTION} context={context} onReload={() => handleReloadPage()} />
          </React.Fragment>
        </Router>
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { FileUploadMutation } from '../../data/graphql';
import cogoToast from 'cogo-toast';
import { useMutation } from 'graphql-hooks';
import Modal from 'react-modal';
import FilePicker from './FilePicker';
import { getExtension, isImage, isVideo } from '../../../utils';
import ReactPlayer from 'react-player';
import { v4 } from 'uuid';
import ButtonWithIndicator from '../../../components/common/ButtonWithIndicator';
import TextInput from './components/TextInput';
import Switch from 'react-switch';
import { useDropzone } from 'react-dropzone';
import { map } from 'lodash';

let fileSelector = null;
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: { zIndex: 9999 }
};

const FileUrlFieldEditor = ({ definition, value, onChange, type, className, breadcrumbs, onBack, containerClassName = 'builder-mt-4' }) => {
  const [filesModalVisible, setFilesModalVisible] = useState(false);
  const [urlModalVisible, setUrlModalVisible] = useState(false);
  const [urlModelValue, setUrlModelValue] = useState('');
  const [mutation, { loading: uploadingFile }] = useMutation(FileUploadMutation);
  const [gridCols, setGridCols] = useState(4);
  const [activeFilter, setActiveFilter] = useState(false);
  const [searchValue, setSearchValue] = useState(undefined);
  const [triggerRefetch, setTriggerRefetch] = useState(0);

  const [myAcceptedFiles, setMyAcceptedFiles] = useState(undefined);
  const [myAcceptedFileItems, setMyAcceptedFileItems] = useState(undefined);
  const [myRejectedFileItems, setMyRejectedFileItems] = useState(undefined);

  const acceptedImageExtensions = {
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/svg+xml': [], // MIME for .svg
      'image/webp': [],
      'image/vnd.microsoft.icon': [] // MIME for .ico
    }
  };
  const acceptedVideoExtensions = {
    accept: {
      'video/mp4': [],
      'video/quicktime': [], // MIME for .mov
      'video/x-m4v': [] // MIME for .m4v
    }
  };
  const acceptedExtensions = type === 'image' ? acceptedImageExtensions : acceptedVideoExtensions;
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone(acceptedExtensions);

  useEffect(() => {
    const acceptedFileItems = acceptedFiles.map((file) => (
      <li key={file.path} className='builder-mb-1 builder-text-sm'>
        {file.path} - {file.size} bytes
      </li>
    ));

    setMyAcceptedFiles(acceptedFiles);
    setMyAcceptedFileItems(acceptedFileItems);
  }, [acceptedFiles]);

  useEffect(() => {
    const rejectedFileItems = fileRejections.map(({ file, errors }) => (
      <li key={file.path} className='builder-mb-1 builder-text-sm builder-text-red-600'>
        {file.path} - {file.size} bytes
        <ul className='builder-list-decimal builder-list-inside builder-ml-5 builder-mt-1'>
          {errors.map((e) => (
            <li key={e.code} className='builder-mb-1 builder-text-sm builder-text-gray-500'>
              {e.message}
            </li>
          ))}
        </ul>
      </li>
    ));

    setMyRejectedFileItems(rejectedFileItems);
  }, [fileRejections]);

  const showFileSelector = (e) => {
    e.preventDefault();

    fileSelector = document.createElement('input');
    fileSelector.setAttribute('id', v4());
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');

    fileSelector.addEventListener('change', (e) => {
      handleChangeImage(e);
    });

    fileSelector.setAttribute('accept', type === 'image' ? '.png,.jpg,.jpeg,.svg,.webp,.ico' : '.mp4,.mov,.m4v');
    fileSelector.click();
  };

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    mutation({ variables: { file: file } })
      .then((res) => {
        const { ok, url } = res.data.fileUpload;
        if (ok) {
          onChange(url);
          cogoToast.success('File uploaded!');
        } else {
          cogoToast.error('Error uploading file!');
        }
      })
      .catch((error) => {
        cogoToast.error('Error uploading file!');
      });
  };

  const handleSelectExisting = (file) => {
    setFilesModalVisible(false);
    onChange(file.url);
  };

  const handleDelete = () => {
    onChange('');
  };

  const handleFilterUsed = (value) => {
    activeFilter === value ? setActiveFilter(false) : setActiveFilter(value);
  };

  const handleUploadFiles = () => {
    map(myAcceptedFiles, (file) => {
      mutation({ variables: { file: file } })
        .then((res) => {
          const { ok } = res.data.fileUpload;

          if (ok) {
            cogoToast.success('image uploaded!');
          } else {
            cogoToast.error('Error uploading file!');
          }
        })
        .catch((error) => {
          cogoToast.error('Error uploading file!');
        });
    });

    // Retrigger fetch files in filePicker
    // Reset accepted & rejected files
    setTriggerRefetch(triggerRefetch + 1);
    setMyAcceptedFiles(undefined);
    setMyAcceptedFileItems(undefined);
    setMyRejectedFileItems(undefined);
  };

  return (
    <React.Fragment>
      {/* <EditorHeader
        breadcrumbs={breadcrumbs}
        onBack={onBack}
        onClick={onBack}
        description={definition.description}
        renderMenu={() => {
          return (
            <div className='builder-flex'>
              {value && value !== '' && <ButtonWithIndicator loading={false} onClick={handleDelete} icon='fas fa-trash-alt' text='Delete' colorClass='builder-text-red-500 builder-bg-white' borderClass='builder-border builder-border-bg-red-500' />}

              <ButtonWithIndicator loading={false} onClick={() => setFilesModalVisible(true)} icon='fas fa-images' text='Choose existing' colorClass='builder-bg-white builder-text-primary' borderClass='builder-border builder-border-bg-primary' />

              <ButtonWithIndicator loading={false} onClick={showFileSelector} icon='fal fa-plus' text='Add new' colorClass='builder-bg-white builder-text-primary' borderClass='builder-border builder-border-bg-primary' />

              <ButtonWithIndicator loading={false} onClick={() => setUrlModalVisible(true)} icon='fal fa-plus' text='Add new (from url)' colorClass='builder-bg-white builder-text-primary' borderClass='builder-border builder-border-bg-primary' />
            </div>
          );
        }}
      /> */}

      <div className={`builder-relative builder-flex ${!value || value === '' ? 'builder-justify-between builder-items-center' : 'builder-justify-center'} ${containerClassName} `}>
        {uploadingFile && <div className='builder-absolute builder-inset-0 builder-bg-gray-600 builder-bg-opacity-25 builder-animate-pulse builder-z-10' style={{ marginLeft: -16, marginRight: -16 }} />}
        {(!value || value === '') && <span className='builder-text-gray-500 builder-whitespace-nowrap'>{type === 'image' ? 'Currently no image selected' : 'Currently no video selected'}</span>}

        {(!value || value === '') && (
          <ButtonWithIndicator
            loading={uploadingFile}
            onClick={() => setFilesModalVisible(true)}
            icon='fal fa-plus'
            text={`${uploadingFile ? 'Uploading ...' : 'Add'}`}
            colorClass='builder-bg-white builder-text-primary'
            borderClass='builder-border builder-border-bg-primary'
          />
        )}

        {value && value !== '' && (
          <div className={`builder-flex-1 builder-p-4 builder-justify-center ${className}`}>
            <div className='builder-flex builder-justify-center builder-items-center'>
              {value && value !== '' && type === 'image' && isImage(value) && (
                <div className='builder-flex builder-flex-row builder-w-full builder-justify-between'>
                  <div className='builder-relative w-full pr-4 mr-2'>
                    <div className='builder-absolute builder-z-10 builder-right-0 builder-top-0 builder-bg-gray-400 builder-p-2 builder-rounded'>
                      <div className=' builder-text-white builder-text-sm builder-uppercase font-medium'>{getExtension(value)}</div>
                    </div>
                    <div className='builder-w-full builder-text-center builder-break-all builder-flex builder-justify-center'>
                      <img src={value} className='builder-object-contain builder-object-center' style={{ maxHeight: 150 }} alt={value} />
                    </div>
                  </div>
                  <div className='builder-flex builder-flex-col'>
                    {value && value !== '' && (
                      <ButtonWithIndicator
                        loading={false}
                        onClick={handleDelete}
                        icon='fas fa-trash-alt'
                        text='Delete'
                        colorClass='builder-text-red-500 builder-bg-white builder-whitespace-nowrap builder-mb-2'
                        borderClass='builder-border builder-border-bg-red-500'
                      />
                    )}

                    <ButtonWithIndicator
                      loading={false}
                      onClick={() => setFilesModalVisible(true)}
                      icon='fas fa-images'
                      text='Edit'
                      colorClass='builder-bg-white builder-text-primary builder-whitespace-nowrap'
                      borderClass='builder-border builder-border-bg-primary'
                    />

                    {/* <ButtonWithIndicator loading={false} onClick={showFileSelector} icon='fal fa-plus' text='Add new' colorClass='builder-bg-white builder-text-primary whitespace-nowrap' borderClass='builder-border builder-border-bg-primary' />

                    <ButtonWithIndicator loading={false} onClick={() => setUrlModalVisible(true)} icon='fal fa-plus' text='Add new (from url)' colorClass='builder-bg-white builder-text-primary whitespace-nowrap' borderClass='builder-border builder-border-bg-primary' /> */}
                  </div>
                </div>
              )}

              {value && value !== '' && type === 'video' && isVideo(value) && (
                <div className='builder-flex builder-flex-row builder-w-full builder-justify-between'>
                  {/* <div>{value}</div> */}
                  <div className='builder-relative w-full pr-4 mr-2'>
                    <ReactPlayer url={value} playing={false} width={266} height={200} />
                  </div>
                  <div className='builder-flex builder-flex-col'>
                    {value && value !== '' && (
                      <ButtonWithIndicator
                        loading={false}
                        onClick={handleDelete}
                        icon='fas fa-trash-alt'
                        text='Delete'
                        colorClass='builder-text-red-500 builder-bg-white builder-whitespace-nowrap builder-mb-2'
                        borderClass='builder-border builder-border-bg-red-500'
                      />
                    )}

                    <ButtonWithIndicator
                      loading={false}
                      onClick={() => setFilesModalVisible(true)}
                      icon='fas fa-images'
                      text='Edit'
                      colorClass='builder-bg-white builder-text-primary builder-whitespace-nowrap'
                      borderClass='builder-border builder-border-bg-primary'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <Modal isOpen={filesModalVisible} onRequestClose={() => setFilesModalVisible(false)} ariaHideApp={false} appElement={document.getElementById('root') || undefined} style={customStyles} contentLabel='Example Modal'>
        <div className='' style={{ width: '90vw', height: '80vh' }}>
          <div>
            <div className='builder-bg-white builder-flex builder-flex-col builder-mb-4 builder-sticky builder-w-full builder-z-10 builder-left-0 builder-right-0' style={{ top: '-20px', paddingTop: '20px', paddingBottom: '20px' }}>
              <div className='builder-grid builder-grid-cols-3'>
                <div className='builder-justify-self-start builder-flex builder-items-center'>
                  <div
                    className='builder-mr-2 builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100'
                    onClick={(e) => {
                      setFilesModalVisible(false);
                      showFileSelector(e);
                    }}
                  >
                    Upload new file
                  </div>
                  <div
                    className='builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100'
                    onClick={(e) => {
                      setFilesModalVisible(false);
                      setUrlModalVisible(true);
                    }}
                  >
                    Add new file from url
                  </div>
                </div>
                <div className='builder-justify-self-center builder-text-2xl builder-flex builder-flex-row builder-w-full builder-items-center builder-justify-center'>
                  <div className='builder-text-sm builder-flex builder-flex-col builder-mr-14'>
                    <label className='builder-flex builder-flex-row builder-items-center builder-mb-1'>
                      <Switch onChange={() => handleFilterUsed('used')} checked={activeFilter === 'used' ? true : false} height={20} className='react-switch builder-mr-1' />
                      <span>Only show used</span>
                    </label>
                    <label className='builder-flex builder-flex-row builder-items-center'>
                      <Switch onChange={() => handleFilterUsed('unused')} checked={activeFilter === 'unused' ? true : false} height={20} className='react-switch builder-mr-1' />
                      <span>Only show unused</span>
                    </label>
                  </div>
                  <div className=''>
                    <i className='fas fa-th-large builder-mr-3 builder-cursor-pointer' onClick={() => setGridCols(4)}></i>
                    <i className='fas fa-th builder-cursor-pointer' onClick={() => setGridCols(6)}></i>
                  </div>
                </div>
                <div className='builder-justify-self-end builder-flex builder-flex-row builder-items-center builder-w-full'>
                  <div className='builder-w-full builder-mr-8'>
                    <TextInput placeHolder='Search image...' className='builder-border builder-border-gray-300 builder-rounded-md' value={searchValue} onChanged={(value) => setSearchValue(value)} />
                  </div>
                  <div
                    className='builder-cursor-pointer'
                    onClick={() => {
                      setFilesModalVisible(false);
                    }}
                  >
                    <i className='fal fa-times builder-text-xl' />
                  </div>
                </div>
              </div>
            </div>

            <div className=''>
              <section className='builder-container builder-mb-4'>
                <div {...getRootProps({ className: 'builder-dropzone builder-border-4 builder-border-dashed builder-border-gray-400 builder-rounded builder-h-16 builder-flex builder-justify-center' })}>
                  <div className='builder-flex builder-flex-col builder-justify-center'>
                    <input {...getInputProps()} />
                    <p className='builder-cursor-pointer'>Drag 'n' drop some files here, or click to select files</p>
                  </div>
                </div>
                {myAcceptedFileItems && myAcceptedFileItems.length > 0 && (
                  <aside className='builder-border builder-rounded builder-mt-4 builder-p-4' style={{ width: '50vw', margin: '0 auto' }}>
                    <h4 className='builder-font-bold builder-mb-4 builder-text-md'>Files to upload</h4>
                    <ul className='builder-list-disc builder-list-inside'>{myAcceptedFileItems}</ul>
                    <div className='builder-mt-4 builder-px-4 builder-py-2 builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-cursor-pointer opacity-100 builder-text-center' onClick={handleUploadFiles}>
                      Upload files
                    </div>
                  </aside>
                )}
                {myRejectedFileItems && myRejectedFileItems.length > 0 && (
                  <aside className='builder-border builder-rounded builder-border-red-600 builder-mt-4 builder-p-4' style={{ width: '50vw', margin: '0 auto' }}>
                    <h4 className='builder-font-bold builder-mb-4 builder-text-md'>Rejected files</h4>
                    <ul className='builder-list-disc builder-list-inside'>{myRejectedFileItems}</ul>
                  </aside>
                )}
              </section>
            </div>

            {/* <div style={{ width: 700, minHeight: 400, maxHeight: 500 }}> */}
            <div
            //style={{ width: '90vw', height: '80vh' }}
            >
              <FilePicker onSelect={handleSelectExisting} type={type} selected={value} gridCols={gridCols} activeFilter={activeFilter} searchValue={searchValue} triggerRefetch={triggerRefetch} />
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={urlModalVisible} onRequestClose={() => setUrlModalVisible(false)} style={customStyles} contentLabel='Url modal'>
        <div className='builder-flex builder-justify-end builder-mb-4'>
          <div
            className='builder-cursor-pointer'
            onClick={() => {
              setUrlModalVisible(false);
            }}
          >
            <i className='fal fa-times builder-text-xl' />
          </div>
        </div>

        <div style={{ width: 700, minHeight: 400, maxHeight: 500 }}>
          <TextInput placeholder='URL' className='builder-border builder-border-gray-300 builder-rounded-md' value={urlModelValue} onChanged={(value) => setUrlModelValue(value)} />

          <ButtonWithIndicator
            loading={false}
            onClick={() => {
              onChange(urlModelValue);
              setUrlModalVisible(false);
            }}
            className='builder-ml-0'
            text='OK'
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default FileUrlFieldEditor;

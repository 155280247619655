import React from 'react';
import App from './App';
import './css/app.css';
import 'react-toggle/style.css';
import { ClientContext } from 'graphql-hooks';
import { client } from './graphqlClient';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ClientContext.Provider value={client}>
    <App />
  </ClientContext.Provider>
);

import React, { useContext, useEffect, useState } from 'react';
import Header from './Header';
import HeaderMenu from '../features/builder/components/HeaderMenu';
import { Menu } from '../features/builder/components/Menu';
import { DispatchContext, StateContext } from '../App';
import { orderBy } from 'lodash';
import { useMutation } from 'graphql-hooks';
import { TRACKING_USER_ACTION_TYPES, TRACKING_USER_MUTATION } from '../features/data/tracking';

const excludedPagesFromLockedState = ['/pages'];

const Template = ({ children, isReleasing, onChangeEnvironment, context }) => {
  const dispatch = useContext(DispatchContext);

  const state = useContext(StateContext);
  const currentState = state.history[state.history.length - 1];

  const [lockedState, setLockedState] = useState(true);

  const [trackingUserMutation] = useMutation(TRACKING_USER_MUTATION);
  useEffect(() => {
    trackingUserMutation({ variables: { data: { route: window.location.pathname, action: TRACKING_USER_ACTION_TYPES.MOVE } } });
  }, [window.location.pathname]);

  useEffect(() => {
    // For now to test on the dev env
    // eslint-disable-next-line no-console
    console.log('users:', state.tracking?.users);
    const users = Object.values(state.tracking?.users).filter((u) => u.route === window.location.pathname);
    if (!users || users.length <= 1 || excludedPagesFromLockedState.includes(window.location.pathname)) {
      setLockedState(false);
      return;
    }
    const firstUserToOpenPage = users.reduce((prev, curr) => (Number(prev.timestamp) < Number(curr.timestamp) ? prev : curr), {});
    if (firstUserToOpenPage.trackingId === state.session?.sessionId) {
      setLockedState(false);
    } else {
      setLockedState(true);
    }
  }, [state.tracking?.users]);

  return (
    <div className='builder-flex builder-flex-1 builder-flex-col builder-h-screen builder-font-body builder-text-sm'>
      {/*HEADER*/}
      <Header renderMenu={() => <HeaderMenu isReleasing={isReleasing} onChangeEnvironment={(websiteId) => onChangeEnvironment(websiteId)} />} onChangeEnvironment={(websiteId) => onChangeEnvironment(websiteId)} />

      <div className='builder-relative builder-flex builder-flex-1 ' style={{ minHeight: 'calc(100% - 48px)' }}>
        <div className=' builder-flex builder-flex-1 builder-h-full ' style={{ backgroundColor: '#dee2ec' }}>
          <Menu context={context} customBlocks={orderBy(currentState.customBlocks, 'description')} />
          <div className='builder-flex builder-w-full builder-flex-1 builder-overflow-x-hidden builder-overflow-y-scroll'>
            <div className='builder-h-fit builder-w-full builder-relative'>
              {lockedState && <div className=' builder-absolute builder-inset-0 builder-z-9999 builder-bg-white builder-opacity-50 builder-h-full'> </div>}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template;

import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import ButtonWithIndicator from '../../components/common/ButtonWithIndicator';
import useBuilderActions from '../builder/useBuilderActions';
import { DispatchContext, StateContext } from '../../App';
import { modalCloseHandler, publishLogClearHandler } from '../data/actions';
import { MODAL_TYPES } from '../data/reducers';
import { find } from 'lodash';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '55%'
  },
  overlay: {
    zIndex: 9999,
    background: '#00000080 0% 0% no-repeat'
  }
};

const PublishDraftToProductionModal = ({ isOpen, onReload, context }) => {
  const { convertDraftToProduction } = useBuilderActions();
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [started, setStarted] = useState(false);

  const handlePublish = () => {
    setStarted(true);
    convertDraftToProduction(website.id);
  };

  const handleClose = () => {
    publishLogClearHandler(dispatch);
    if (started) {
      // Force reload to update all data ..
      onReload();
    } else {
      modalCloseHandler(dispatch, MODAL_TYPES.PUBLISH_DRAFT_TO_PRODUCTION);
    }
  };

  if (!isOpen) return null;

  const website = find(state.websites, (w) => w.id === state.currentWebsite);

  return (
    <div>
      <Modal isOpen={isOpen} style={customStyles} contentLabel='Publish modal' ariaHideApp={false} appElement={document.getElementById('root') || undefined}>
        <div className='builder-flex builder-w-full builder-justify-end builder-mb-5'>
          <i className='fal fa-times builder-text-xl builder-cursor-pointer builder-text-2xl' onClick={() => handleClose()} />
        </div>

        <div className='builder-text-black'>
          Publish <span className='builder-font-bold'>draft to production.</span>
        </div>
        <div className='builder-mb-2.5 builder-text-sm builder-text-gray-500'>This draft will be transferred to production!</div>

        <div className='builder-w-full builder-mt-4'>
          <div style={{ height: 150 }} className='builder-flex builder-flex-col-reverse builder-overflow-auto builder-bg-gray-50 builder-p-2'>
            {!state.publish.output && <div className='builder-text-gray-400 builder-text-sm builder-h-full builder-flex builder-items-center builder-justify-center'>You will see the process info here.</div>}
            {state.publish.output && (
              <div>
                {state.publish.output.split('\n').map((item, i) => (
                  <p key={i}>{item}</p>
                ))}
              </div>
            )}
          </div>
        </div>

        {context.domainName && (
          <a href={`https://${context.domainName}`} target='_blank' rel='noreferrer' className='builder-text-blue-600 builder-cursor-pointer'>
            {`https://${context.domainName}`}
          </a>
        )}

        <div className='builder-flex builder-justify-start builder-mt-6'>
          <ButtonWithIndicator loading={false} onClick={() => handleClose()} className='builder-ml-0' text='Cancel' />

          <ButtonWithIndicator loading={false} onClick={() => handlePublish()} text='Publish' colorClass='builder-bg-primary builder-text-white' borderClass='builder-border builder-border-bg-blue-500' />
        </div>
      </Modal>
    </div>
  );
};
export default PublishDraftToProductionModal;

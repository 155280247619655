import { useContext, useState } from 'react';
import { map } from 'lodash';
import { saveField } from '../../utils';
import cogoToast from 'cogo-toast';
import { DispatchContext, StateContext } from '../../App';
import { v4 } from 'uuid';
import { pageUpdateHandler } from '../data/actions';

export const PagesQuery = `
  query ($data: PageSearchInputType) {
    pages(data: $data) {
      id
      path
      typeName
      category
      slug
      migration
      creationDate
      tags
      indexable
      matchPath
      analyticsName
      analyticsType
      meta {
       id
       title
       description
       language
      }
      properties {
       id     
       type
       data
       language
      }
      blocks {
        id
        order
        contentType
        variant
        content
        campaignId
        hideForCampaign
        analyticsName
      }
    }
}`;

export const PageUpdateMutation = `mutation PageUpdate($data: PageUpdateInputType!) {
  pageUpdate(data: $data) {
      ok
  }
}`;

export const PageUpdateBulkMutation = `mutation PageUpdateBulk($data: [PageUpdateInputType!]!) {
  pageUpdateBulk(data: $data) {
      ok
  }
}`;

export const PageDeleteMutation = `mutation PageDelete($data: PageDeleteInputType!) {
  pageDelete(data: $data) {
      ok
  }
}`;

export const mapPage = (page, isCopy = false, website_id = null) => {
  return {
    id: !isCopy ? page.id : v4(),
    websiteId: website_id,
    path: page.url,
    typeName: page.type,
    category: page.category ? page.category : 'other',
    slug: JSON.stringify(page.slug),
    tags: page.tags,
    indexable: page.indexable,
    analyticsName: page.analyticsName,
    analyticsType: page.analyticsType,
    matchPath: page.matchPath,
    meta: map(page.meta, (meta, index) => {
      return {
        id: !isCopy ? meta.id : v4(),
        language: meta.language,
        title: meta.title,
        description: meta.description
      };
    }),
    properties: map(page.properties, (prop) => {
      return {
        id: !isCopy ? prop.id : v4(),
        type: prop.type,
        data: JSON.stringify(prop.data),
        language: prop.language
      };
    }),
    blocks: map(page.blocks, (block, index) => {
      return {
        id: !isCopy ? block.id : v4(),
        contentType: block.contentType,
        variant: block.componentName,
        order: index,
        content: map(block.content, (content) => {
          return {
            id: !isCopy ? content.id : v4(),
            culture: content.culture,
            fields: map(content.fields, (field) => {
              if (!isCopy) {
                return {
                  name: field.name,
                  value: !isCopy ? saveField(field) : field.value
                };
              } else {
                return {
                  name: field.name,
                  type: field.type,
                  value: !isCopy ? saveField(field) : field.value
                };
              }
            })
          };
        }),
        campaignId: block.campaignId,
        hideForCampaign: block.hideForCampaign,
        analyticsName: block.analyticsName
      };
    })
  };
};

export const PAGE_STATUSES = {
  INITIAL: 'INITIAL',
  SAVING: 'SAVING',
  GETTING: 'GETTING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  DELETING: 'DELETING'
};

export function usePages(context) {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const currentState = state.history[state.history.length - 1];
  const [pageStatus, setPageStatus] = useState(PAGE_STATUSES.INITIAL);

  const handlePageGet = (id, websiteId, onSuccess, onError) => {
    setPageStatus(PAGE_STATUSES.GETTING);
    const variables = {
      data: { id, websiteId }
    };

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PagesQuery, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        setPageStatus(PAGE_STATUSES.INITIAL);
        const page = result?.data?.pages?.[0];
        if (!page) {
          cogoToast.error('Get page failed');
          return;
        }

        pageUpdateHandler(dispatch, page.id, page);
      })
      .catch((err) => {
        setPageStatus(PAGE_STATUSES.ERROR);
        cogoToast.error(err);
        onError && typeof onError === 'function' && onError(err);
      });
  };

  const handlePageSave = (updatedPage, websiteId, onSuccess, onError) => {
    setPageStatus(PAGE_STATUSES.SAVING);
    const variables = {
      data: mapPage(updatedPage, false, websiteId)
    };

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PageUpdateMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.pageUpdate?.ok) {
          cogoToast.success('Page saved.');
          setPageStatus(PAGE_STATUSES.SUCCESS);
          onSuccess && typeof onSuccess === 'function' && onSuccess();
        } else {
          cogoToast.error('Page could not be saved.');
          setPageStatus(PAGE_STATUSES.ERROR);
          onError && typeof onError === 'function' && onError();
        }
      })
      .catch((err) => {
        setPageStatus(PAGE_STATUSES.ERROR);
        cogoToast.error(err);
        onError && typeof onError === 'function' && onError(err);
      });
  };

  const handlePagesSave = (updatedPages, websiteId, onSuccess) => {
    setPageStatus(PAGE_STATUSES.SAVING);
    const variables = {
      data: map(updatedPages, (updatedPage) => mapPage(updatedPage, false, websiteId))
    };

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PageUpdateBulkMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.pageUpdateBulk?.ok) {
          cogoToast.success('Pages saved.');
          setPageStatus(PAGE_STATUSES.SUCCESS);
          onSuccess && typeof onSuccess === 'function' && onSuccess();
        } else {
          cogoToast.error('Pages could not be saved.');
          setPageStatus(PAGE_STATUSES.ERROR);
        }
      })
      .catch((err) => {
        setPageStatus(PAGE_STATUSES.ERROR);
        cogoToast.error(err);
      });
  };

  const handleDeletePage = (pageId, websiteId, onSuccess) => {
    setPageStatus(PAGE_STATUSES.DELETING);
    const variables = {
      data: {
        id: pageId,
        websiteId: websiteId
      }
    };

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: PageDeleteMutation, variables: variables })
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result?.data?.pageDelete?.ok) {
          cogoToast.success('Page deleted.');
          setPageStatus(PAGE_STATUSES.SUCCESS);
          onSuccess && typeof onSuccess === 'function' && onSuccess();
        } else {
          cogoToast.error('Page could not be deleted.');
          setPageStatus(PAGE_STATUSES.ERROR);
        }
      })
      .catch((err) => {
        setPageStatus(PAGE_STATUSES.ERROR);
        cogoToast.error(err);
      });
  };

  return {
    dispatch,
    getPage: (id, websiteId, onSuccess, onError) => handlePageGet(id, websiteId, onSuccess, onError),
    savePage: (updatedPage, websiteId, onSuccess, onError) => handlePageSave(updatedPage, websiteId, onSuccess, onError),
    savePages: (updatedPages, websiteId, onSuccess) => handlePagesSave(updatedPages, websiteId, onSuccess),
    deletePage: (pageId, websiteId, onSuccess) => handleDeletePage(pageId, websiteId, onSuccess),
    pageStatus: pageStatus
  };
}

export default usePages;

import { v4 } from 'uuid';

export const generalSettingsRoutes = {
  SETTINGS_GENERAL_CONFIG: '/settings/general-config',
  SETTINGS_FILE_UPLOADER: '/settings/file-uploader',
  SETTINGS_RELEASE: '/settings/release',
  SETTINGS_BACKUP_AND_RESTORE: '/settings/backup-and-restore',
  SETTINGS_PAGE_TYPES: '/settings/page-types',
  SETTINGS_REDIRECTS: '/settings/redirects',
  SETTINGS_MAKES: '/settings/makes'
};

export const routes = {
  HOME: '/',
  BUILDER: '/builder',
  LOGIN: '/login',
  CAMPAIGNS: '/campaigns',
  CAMPAIGN_DETAIL: '/campaign-detail',
  BLOG: '/blog',
  BLOG_DETAIL: '/blog-detail',
  BLOCKS: '/blocks',
  BLOCKS_DETAIL: '/blocks-detail',
  ADVERTISEMENTS: '/advertisements',
  ADVERTISEMENT_DETAIL: '/advertisement-detail',
  FAQ_OVERVIEW: '/faq-overview',
  FAQ_TOPICS: '/faq-topics',
  FAQ_TOPIC_DETAIL: '/faq-topic-detail',
  FAQ_CATEGORY_DETAIL: '/faq-category-detail',
  FAQ_QUESTION_GROUP_DETAIL: '/faq-question-group-detail',
  FAQ_QUESTION_DETAIL: '/faq-question-detail',
  VEHICLE_FILTERS: '/vehicle-filters',
  VEHICLE_FILTERS_DETAIL: '/vehicle-filters-detail',
  PAGES: '/pages',
  ...generalSettingsRoutes,
  SETTINGS_DEALERSHIPS: '/settings/dealerships',
  SETTINGS_DEALERSHIP: '/settings/dealership',
  WAIT: '/wait',
  CUSTOM_BLOCKS: '/custom-blocks',
  CUSTOM_BLOCK: '/custom-block',
  SEO_OVERVIEW: '/seo-overview',
  SEO_DETAILS: '/seo-details'
};

export const languagesTranslate = {
  nl: 'Nederlands',
  fr: 'Frans',
  en: 'Engels',
  de: 'Duits'
};

export const settingsMenu = {
  general: 'general',
  dealerships: 'dealerships'
};

export const settingsMenuItems = [
  {
    label: settingsMenu.general,
    icon: 'far fa-globe-asia',
    url: routes.SETTINGS_GENERAL_CONFIG
  },
  {
    label: settingsMenu.dealerships,
    icon: 'fal fa-home',
    url: routes.SETTINGS_DEALERSHIPS
  }
];

export const locations = {
  workplace: 'workplace',
  showroom: 'showroom'
};

export const locationTypes = [{ type: locations.workplace }, { type: locations.showroom }];

export const timerTypes = {
  start: 'start',
  stop: 'stop'
};

export const initHours = {
  id: '',
  start: '',
  stop: '',
  type: '',
  day: '',
  reference: '',
  uuid: '',
  order: 1
};
export const initClosingDays = {
  id: '',
  start: '',
  stop: '',
  type: '',
  reference: '',
  uuid: '',
  description: '',
  order: 1
};

export const openingsHoursInit = [
  { ...initHours, day: 'mon', uuid: v4(), type: locations.workplace },
  { ...initHours, day: 'tue', uuid: v4(), type: locations.workplace },
  { ...initHours, day: 'wed', uuid: v4(), type: locations.workplace },
  { ...initHours, day: 'thu', uuid: v4(), type: locations.workplace },
  { ...initHours, day: 'fri', uuid: v4(), type: locations.workplace },
  { ...initHours, day: 'sat', uuid: v4(), type: locations.workplace },
  { ...initHours, day: 'sun', uuid: v4(), type: locations.workplace },
  { ...initHours, day: 'mon', uuid: v4(), type: locations.showroom },
  { ...initHours, day: 'tue', uuid: v4(), type: locations.showroom },
  { ...initHours, day: 'wed', uuid: v4(), type: locations.showroom },
  { ...initHours, day: 'thu', uuid: v4(), type: locations.showroom },
  { ...initHours, day: 'fri', uuid: v4(), type: locations.showroom },
  { ...initHours, day: 'sat', uuid: v4(), type: locations.showroom },
  { ...initHours, day: 'sun', uuid: v4(), type: locations.showroom }
];

export const closingDaysInit = [
  { ...initClosingDays, uuid: v4(), type: locations.workplace },
  { ...initClosingDays, uuid: v4(), type: locations.showroom }
];

export const initDealership = {
  id: '',
  name: '',
  address: '',
  postal: '',
  city: '',
  reference: '',
  email: '',
  facebook: '',
  linkedin: '',
  twitter: '',
  instagram: '',
  fax: '',
  tel: '',
  url: '',
  image: '',
  openingsHours: [],
  closingDays: []
};
